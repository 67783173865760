import React from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { Stack, Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

interface Props {
	belopp: number
	type: PaymentType
	content: IPaymentContent
}

export const KrPerVecka = ({ belopp, type, content }: Props) => {
	const label = pickLabel(content, type, 'amountPerWeekDetails')

	return (
		<Stack direction="row" spacing={1} mt={2} data-testid="kr-per-vecka">
			<Typography variant="body2">{label}</Typography>
			<Typography variant="body2">{` ${formatCurrencySEK(Math.abs(belopp))}`}</Typography>
		</Stack>
	)
}
