import React from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { Grid, Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

interface Props {
	skattesats: number
	skatteavdrag: number
	type: PaymentType
	content: IPaymentContent
}

export const Skatt = ({ skattesats, skatteavdrag, type, content }: Props) => {
	const skattLabel =
		type === 'praktiklon' || type === 'studielon' || type === 'startlon' ? '(Skattetabell) ' : skattesats > 0 && ` (${skattesats}%) `

	const label = pickLabel(content, type, 'taxDetails')

	return (
		<Grid container rowSpacing={2} data-testid="skatt">
			<Grid item xs={4} sm={2} pr={1}>
				<Typography variant="body2">
					{label} {skattLabel}
				</Typography>
			</Grid>
			<Grid item xs="auto">
				<Typography variant="body2">{formatCurrencySEK(Math.abs(skatteavdrag))}</Typography>
			</Grid>
		</Grid>
	)
}
