import React, { Fragment } from 'react'
import { useSelector } from '@local/src/Store/storeContext'
import { Box, Pagination } from '@mui/material'

import { Loader } from '../Components/Loader'
import SystemFeedback from '../Components/SystemFeedback'
import EmptyResult from '../Components/EmptyResult/EmptyResult'

import { IPayment, IPayments } from './Payments.types'
import { PaymentsItem } from './PaymentsItem'
import { isAgeLoadingSelector } from './Payments.selectors'

const Payments = ({ payments, paymentsLoaded, setPage, pagination, content }: IPayments) => {
	const isAgeLoading = useSelector(isAgeLoadingSelector)
	const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value)
	}

	const showPaymentMaintenanceInfoText =
		content.underhallsinformationtext?.trim() && payments.some((p) => p.typ === 'kompletterandestudiestod')

	if (!paymentsLoaded || isAgeLoading) return <Loader />

	if (Number(payments?.length) === 0) {
		return (
			<Box>
				<SystemFeedback
					isVisible={showPaymentMaintenanceInfoText}
					message={content.underhallsinformationtext}
					severity={content.underhallsinformationtyp}
				/>

				<Box mt={8}>
					<EmptyResult data-testid="no-payments" heading={content.emptylisttitle} description={content.emptylistbody} />
				</Box>
			</Box>
		)
	}

	return (
		<Fragment>
			<SystemFeedback
				isVisible={showPaymentMaintenanceInfoText}
				message={content.underhallsinformationtext}
				severity={content.underhallsinformationtyp}
			/>

			{payments?.map((payment: IPayment, index) => (
				<PaymentsItem payment={payment} content={content} key={`${payment?.planeratUtbetalningsdatum} - ${payment?.typ} - ${index}`} />
			))}

			{pagination.totalPages > 1 && (
				<Box mt={6}>
					<Pagination count={pagination.totalPages} page={pagination?.currentPage} onChange={handleChange} />
				</Box>
			)}
		</Fragment>
	)
}

export default Payments
