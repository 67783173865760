import React from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { isKksAterbetalning } from '@local/src/Payments/Payments.helpers'
import { Grid, Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

interface Props {
	type: PaymentType
	content: IPaymentContent
	nettobelopp: number
	expanded?: boolean
}

export const Netto = ({ type, content, nettobelopp, expanded }: Props) => {
	let contentString
	if (type === 'loneavdrag') {
		contentString = expanded ? 'deductedAmountDetails' : 'deductedAmount'
	} else if (type === 'aterbetalning' || type === 'avbrutenutbetalning' || isKksAterbetalning(type)) {
		contentString = expanded ? 'amountDetails' : 'amount'
	} else {
		contentString = expanded ? 'paidAmountDetails' : 'paidAmount'
	}

	const label = pickLabel(content, type, contentString)

	return expanded ? (
		<Grid container rowSpacing={2}>
			<Grid item xs={4} sm={2} pr={1}>
				<Typography variant="body2">{label}</Typography>
			</Grid>
			<Grid item xs="auto">
				<Typography variant="body2">{` ${formatCurrencySEK(Math.abs(nettobelopp))} *`}</Typography>
			</Grid>
		</Grid>
	) : (
		<Typography variant="body2" data-testid="netto">
			{label}
			<b>{` ${formatCurrencySEK(Math.abs(nettobelopp))} *`}</b>
		</Typography>
	)
}
