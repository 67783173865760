import React from 'react'
import { Provider } from 'react-redux'
import { AppShellRouter, KeyRoute } from '@local/src/AppShellRouter'
import { PaymentsFeedTab } from '@local/src/Pages'
import { AppShellContext } from '@local/src/AppShellContext'
import { createTheme, ThemeProvider } from '@mui/material'
import { themeOptions, defaultLocale } from '@trr/mui-theme'
import { GTMTracker } from '@trr/gtm-tracking'

import { IAppShellConfiguration } from './App.types'
import Store from './Store/store'
import { UtbetalningarContext } from './Store/storeContext'
import { objectFirstLevelToLowerCase } from './Utils/Helpers'
import { IPaymentContent } from './Payments/Payments.types'

const App: React.FunctionComponent<IAppShellConfiguration> = ({ currentKey, device, enabledFeatures, content }) => {
	const genomfordaUtbetalningarKey = 'genomforda-utbetalningar'
	const kommandeUtbetalningarKey = 'kommande-utbetalningar'

	const getUtbetalningarContent = (key: string): IPaymentContent =>
		objectFirstLevelToLowerCase(
			content[key === kommandeUtbetalningarKey ? 'kommandeutbetalningar' : 'genomfordautbetalningar']
		) as unknown as IPaymentContent

	const theme = createTheme(themeOptions, defaultLocale)

	return (
		<ThemeProvider theme={theme}>
			<Provider store={Store} context={UtbetalningarContext}>
				<AppShellContext.Provider
					value={{
						enabledFeatures,
						content,
					}}
				>
					<GTMTracker mfName="utbetalningar">
						<AppShellRouter currentKey={currentKey}>
							<KeyRoute urlKey={genomfordaUtbetalningarKey}>
								<PaymentsFeedTab device={device} isGenomfordaUtbetalningar content={getUtbetalningarContent(currentKey)} />
							</KeyRoute>
							<KeyRoute urlKey={kommandeUtbetalningarKey}>
								<PaymentsFeedTab device={device} content={getUtbetalningarContent(currentKey)} />
							</KeyRoute>
						</AppShellRouter>
					</GTMTracker>
				</AppShellContext.Provider>
			</Provider>
		</ThemeProvider>
	)
}

export default App
