import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from '@local/Store/storeContext'
import { paginationSelector, hasAktivtAgeArendeSelector, paymentsFailedSelector } from '@local/Payments/Payments.selectors'
import { getPageFromUrlParams, buildQuery } from '@local/Payments/Payments.helpers'
import { useHistory } from 'react-router-dom'
import { useDidMount } from '@local/Utils/Hooks'
import { getOIDCUser } from '@trr/app-shell-communication'

import { IPaymentContent } from '../Payments/Payments.types'
import { getPayments, setPage } from '../Payments/Payments.actions'
import Payments from '../Payments'
import { IDevice } from '../App.types'
import { PreliminarSkattInfo } from '../Payments/Fragments/Common/PreliminarSkattInfo'
import { UtbetalningarAvAGE } from '../Payments/Fragments/Common/UtbetalningarAvAGE'
import { UtbetalningstidInfoBox } from '../Payments/Fragments/Common/UtbetalningstidInfoBox'
import PaymentInfoBlock from '../Payments/PaymentInfoBlock'

interface Props {
	device: IDevice
	isGenomfordaUtbetalningar?: boolean
	content: IPaymentContent
}

const PaymentsFeedTab = ({ device, isGenomfordaUtbetalningar = false, content }: Props) => {
	const dispatch = useDispatch()
	const paymentsFailed = useSelector(paymentsFailedSelector)
	const history = useHistory()
	const pagination = useSelector(paginationSelector)
	const isFirstRender = !useDidMount()
	const klientId = getOIDCUser()?.profile?.sub
	const hasAktivtAgeArende = useSelector(hasAktivtAgeArendeSelector)

	useEffect(() => {
		// Sets page and get payments by url query on first load
		if (isFirstRender) {
			const query = getPageFromUrlParams(window.location.search)
			dispatch(setPage(query))
			return
		}
		// When component mounted, update history url and get payments by page query params
		const query = buildQuery(pagination.currentPage, isGenomfordaUtbetalningar)
		history.push(query)
		dispatch(
			getPayments({
				klientId,
				query,
			})
		)
	}, [pagination.currentPage, dispatch, history, isFirstRender, klientId, isGenomfordaUtbetalningar])

	if (paymentsFailed) {
		return <div data-testid="tab-wrapper-error">Något gick fel.</div>
	}

	return (
		<Fragment>
			<PaymentInfoBlock content={content} />

			{hasAktivtAgeArende && (
				<UtbetalningarAvAGE headerContent={content.utbetalningaravageheader} content={content.utbetalningaravagetext} />
			)}
			<UtbetalningstidInfoBox content={content.utbetalningstidinfoboxtext} />
			<PreliminarSkattInfo content={content.preliminarskattinfotext} />
			<Payments device={device} content={content} />
		</Fragment>
	)
}

export default PaymentsFeedTab
