/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { ReactElement, ReactNode } from 'react'

export const KeyRoute = ({ children }: { children: ReactElement; urlKey: string }) => children

export const AppShellRouter = ({ currentKey, children }: { currentKey: string; children: ReactNode }) => {
	let component

	React.Children.forEach(children, (child) => {
		if (React.isValidElement(child)) {
			if (child.props.urlKey === currentKey) {
				component = child
			}
		} else {
			component = null
		}
	})

	return component ?? null
}
