import React from 'react'
import { IPaymentContent, PaymentType } from '@local/Payments/Payments.types'
import { formatCurrencySEK } from '@local/Utils/Helpers'
import { Grid, Typography } from '@mui/material'

import { pickLabel } from '../Fragments.helpers'

interface Props {
	bruttobelopp: number
	type: PaymentType
	content: IPaymentContent
}

export const Brutto = ({ bruttobelopp, type, content }: Props) => {
	const label = pickLabel(content, type, 'beforeTaxDetails')

	return (
		<Grid container rowSpacing={2} data-testid="brutto">
			<Grid item xs={4} sm={2} pr={1}>
				<Typography variant="body2">{label}</Typography>
			</Grid>
			<Grid item xs="auto">
				<Typography variant="body2">{` ${formatCurrencySEK(Math.abs(bruttobelopp))}`}</Typography>
			</Grid>
		</Grid>
	)
}
